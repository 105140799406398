import logo from "./logo.svg";
import "./App.css";
import Dashboard from "./screens/Dashboard";
import BottomNav from "./components/BottomNav";
import Sponsers from "./components/Sponsers";
import getWindowSize from "./components/getWindowSize";
import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Routes, redirect } from "react-router-dom";
import SpecificTeam from "./screens/SpecificTeam";
import About from "./screens/About";
import Teams from "./screens/Teams";
import Events from "./screens/Events";
import SpecificGame from "./screens/SpecificGame";
import Scores2022 from "./screens/2022/Scores";
import Scores2024 from "./screens/2024/Scores";

function App() {
  const [value, setValue] = useState(2);
  console.log(value);
  let year = window.location.pathname.split("/")[1] || 2024;
  if (!year || year == "") {
    console.log("need to redirect");
    year = "2024";
    redirect("/2024");
  }

  const data = require(`./data${year}.json`);
  const [height, setHeight] = useState(300);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [divHeight, setDivHeight] = useState(90);
  const ref = useRef();
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
      window.scrollTo(0, 0);
    };
  }, []);

  useEffect(() => {
    if (windowSize && windowSize.innerHeight) setHeight(windowSize.innerHeight - 50 - divHeight);
  }, [windowSize]);

  useEffect(() => {
    if (windowSize && windowSize.innerHeight) setHeight(windowSize.innerHeight - 50 - divHeight);
  }, [divHeight]);

  return (
    <div className="App">
      <div
        style={{
          height: height,
          overflowY: "scroll",
          top: divHeight,
          position: "relative",
        }}
        ref={ref}
      >
        <Router>
          <Routes>
            <Route path="/" element={<Dashboard data={data} setDivHeight={setDivHeight} />}></Route>

            <Route path="/2024" element={<Dashboard data={data} setDivHeight={setDivHeight} />}></Route>
            <Route path="/2024/teams" element={<Teams data={data} setDivHeight={setDivHeight} width={windowSize.innerWidth} />} />
            <Route path={`/2024/team`} element={<SpecificTeam data={data} width={windowSize.innerWidth} setDivHeight={setDivHeight} />} />
            <Route path={"/2024/games"} element={<Scores2024 data={data} setDivHeight={setDivHeight} />} />
            <Route path={"/2024/about"} element={<About data={data} setDivHeight={setDivHeight} />} />
            <Route path={"/2024/events"} element={<Events data={data} setDivHeight={setDivHeight} />} />
            <Route path={`/2024/game`} element={<SpecificGame data={data} width={windowSize.innerWidth} setDivHeight={setDivHeight} />} />
            <Route path="/2022" element={<Dashboard data={data} setDivHeight={setDivHeight} />}></Route>
            <Route path="/2022/teams" element={<Teams data={data} setDivHeight={setDivHeight} width={windowSize.innerWidth} />} />
            <Route path={`/2022/team`} element={<SpecificTeam data={data} width={windowSize.innerWidth} setDivHeight={setDivHeight} />} />
            <Route path={"/2022/games"} element={<Scores2022 data={data} setDivHeight={setDivHeight} />} />
            <Route path={"/2022/about"} element={<About data={data} setDivHeight={setDivHeight} />} />
            <Route path={"/2022/events"} element={<Events data={data} setDivHeight={setDivHeight} />} />
            <Route path={`/2022/game`} element={<SpecificGame data={data} width={windowSize.innerWidth} setDivHeight={setDivHeight} />} />
          </Routes>
          <Sponsers />
          <BottomNav value={value} setValue={setValue} />
        </Router>
      </div>
    </div>
  );
}

export default App;
