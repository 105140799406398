import { useLocation, useNavigate, useParams } from "react-router-dom";
import GenericHeader from "../components/GenericHeader";
import GenericTable from "../components/GenericTable";
import React, { useEffect } from "react";

export default function SpecificGame({ width, setDivHeight, data }) {
  let location = useLocation();
  const navigate = useNavigate();
  const team = location.state.game;
  const path = team.photoPath;
  let height = 300;
  if (width < 400) {
    height = 100;
  }
  const history = useLocation();
  useEffect(() => {
    document.querySelector("#some-container").scrollIntoView();
  }, [history]);
  return (
    <div>
      <GenericHeader setDivHeight={setDivHeight} title={team.name} backAction={() => navigate(`/${data.year}/games`)} />
      <div className={"container"} id={"some-container"}>
        {/*<div style={{backgroundColor: PRIMARY}}>*/}
        {/*    <img*/}
        {/*        src={require(`../images/${path}`)}*/}
        {/*        alt="Team image"*/}
        {/*        height={height}*/}

        {/*        style={{ alignSelf: "center", maxWidth: width, maxHeight: 200 }}*/}
        {/*    />        </div>*/}
        <h3 className={"headers"}>Objectives</h3>
        <div style={{ paddingLeft: 10 }}>
          <p>{team.objective}</p>
        </div>
        {team.points && (
          <div>
            <h3 className={"headers"}>Points</h3>
            <div style={{ paddingLeft: 10 }}>
              <p>{team.points}</p>
              {team.table && <GenericTable headers={data.specificGameTablePointsHeaders} data={data.SpecificGameTablePoints} />}
            </div>
          </div>
        )}
        <h3 className={"headers"}>Rules</h3>
        <div style={{ paddingLeft: 10 }}>
          <p>{team.rules}</p>
          {team.page && (
            <p>
              For rules click <a href={data.rulesRegulationsLink}>here</a>
            </p>
          )}
        </div>

        <p>{team.others}</p>
      </div>
    </div>
  );
}
