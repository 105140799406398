import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import getWindowSize from "./getWindowSize";
import React, { useState, useEffect, useRef } from "react";
import { PRIMARY } from "./Colors";
//data: [{'leaders': abs,...}, ...]
//headers: ['leaders', ...]
export default function GenericTable({ headers, data, title }) {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <div>
      {title && <h3 className="headers">{title}</h3>}
      <TableContainer>
        <Table sx={{ width: "100%" }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow style={{ backgroundColor: PRIMARY }}>
              {headers.map((h) => {
                return <TableCell style={{ color: "white" }}>{h}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row, i) => {
                return (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {headers.map((h) => {
                      return <TableCell>{row[h]}</TableCell>;
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
