import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyC1bmsdIKcHgR_00n9kzjQKHn54ChnpkFw",
    authDomain: "championshipapp-2b6a2.firebaseapp.com",
    databaseURL: "https://championshipapp-2b6a2-default-rtdb.firebaseio.com",
    projectId: "championshipapp-2b6a2",
    storageBucket: "championshipapp-2b6a2.appspot.com",
    messagingSenderId: "719946002862",
    appId: "1:719946002862:web:2aaba88bc3322c28f9183f",
    measurementId: "G-3ZRSXJDBCR"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
