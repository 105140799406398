import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PRIMARY } from "./Colors";
import { AppBar } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect, useRef } from "react";
export default function GenericHeader({ title, backAction, setDivHeight }) {
  const ref = useRef(null);
  const year = window.location.pathname.split("/")[1] || 2024;
  const logo = require(`../images/logo_${year}.png`);
  useEffect(() => {
    setDivHeight(ref.current.clientHeight);
    console.log("height: ", ref.current.clientHeight);

    console.log("width: ", ref.current.clientWidth);
  }, []);

  useEffect(() => {
    console.log(title);
    setDivHeight(ref.current.clientHeight);
    console.log("height: ", ref.current.clientHeight);

    console.log("width: ", ref.current.clientWidth);
  }, [title]);
  return (
    <AppBar
      ref={ref}
      style={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: PRIMARY,
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 10,
      }}
    >
      <ArrowBackIcon color={"white"} style={{ visibility: backAction ? "visible" : "hidden" }} onClick={backAction} fontSize={"large"} />
      <h2>{title}</h2>
      <img src={logo} alt="Logo" width={48} height={48} />
    </AppBar>
  );
}
