import { PRIMARY } from "./Colors";
import uni from "../images/supportBy/uni.png";
import React, { useState, useEffect } from "react";
import getWindowSize from "./getWindowSize";
import ieee from "../images/main/IEEE.jpg";
import cs from "../images/main/ieecs.png";
import sac from "../images/supportBy/ieee_cs.png";
import pea from "../images/main/PEA-Horizontal.png";
import r8 from "../images/main/r8logo.png";
import csr from "../images/supportBy/cassar ship repair.jpeg";
import ieeer8 from "../images/supportBy/IEEE R8 SAC logo.png";
import ps_youth from "../images/main/ps_youth.png";
import ieeer8cd from "../images/supportBy/ieee_cs.png";
import mcast from "../images/supportBy/mcast.jpg";
export default function Sponsers() {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const year = window.location.pathname.split("/")[1] || 2024;
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const mainSponsors =
    year == "2022" ? (
      <div>
        <a href={"http://www.ieeemalta.org/"}>
          <img src={ieee} alt="Logo" style={{ padding: 2, margin: 5 }} width={windowSize.innerWidth / 4} />
        </a>
        <a href={"https://www.computer.org/"}>
          <img src={cs} alt="Logo" style={{ padding: 2, margin: 5 }} width={windowSize.innerWidth / 4} />
        </a>
        <a href={"https://ieeer8.org"}>
          <img src={r8} alt="Logo" style={{ padding: 2, margin: 5 }} width={windowSize.innerWidth / 4} />
        </a>
        <a href={"https://ieeer8.org/category/technical-activities/professional-and-educational-activities/"}>
          <img src={pea} alt="Logo" style={{ padding: 2, margin: 5 }} width={windowSize.innerWidth / 4} />
        </a>
      </div>
    ) : (
      <div>
        {/* <a href={"https://www.computer.org/"}> */}
        <img src={ps_youth} alt="Logo" style={{ padding: 2, margin: 5 }} width={windowSize.innerWidth / 4} />
        {/* </a> */}
        <a href={"http://www.ieeemalta.org/"}>
          <img src={ieee} alt="Logo" style={{ padding: 2, margin: 5 }} width={windowSize.innerWidth / 4} />
        </a>

        <a href={"https://ieeer8.org"}>
          <img src={r8} alt="Logo" style={{ padding: 2, margin: 5 }} width={windowSize.innerWidth / 4} />
        </a>
      </div>
    );
  const supportBy =
    year == "2022" ? (
      <div>
        <a href={"https://www.um.edu.mt/"}>
          <img src={uni} alt="Logo" style={{ padding: 2, margin: 5 }} width={windowSize.innerWidth / 4} />
        </a>
        <a href={"https://ieeer8.org/student-activities/sa-committee/sac/"}>
          <img src={ieeer8} alt="Logo" style={{ padding: 2, margin: 5 }} width={windowSize.innerWidth / 4} />
        </a>
        <a href={"https://cassarmarinegroup.com/"}>
          <img src={csr} alt="Logo" style={{ padding: 2, margin: 5 }} width={windowSize.innerWidth / 4} />
        </a>
      </div>
    ) : (
      <div>
        <div>
          <a href={"https://www.um.edu.mt/"}>
            <img src={uni} alt="Logo" style={{ padding: 2, margin: 5 }} width={windowSize.innerWidth / 4} />
          </a>
          <a href={"https://cassarmarinegroup.com/"}>
            <img src={csr} alt="Logo" style={{ padding: 2, margin: 5 }} width={windowSize.innerWidth / 4} />
          </a>

          <a href={"https://mcast.com.mt/"}>
            <img src={mcast} alt="Logo" style={{ padding: 2, margin: 5 }} width={windowSize.innerWidth / 4} />
          </a>
        </div>
        <div>
          <a href={"https://ieeer8.org/student-activities/sa-committee/sac/"}>
            <img src={ieeer8} alt="Logo" style={{ padding: 2, margin: 5 }} width={windowSize.innerWidth / 4} />
          </a>
          <a href={"https://ieeer8.org/student-activities/sa-committee/sac/"}>
            <img src={ieeer8cd} alt="Logo" style={{ padding: 2, margin: 5 }} width={windowSize.innerWidth / 4} />
          </a>
          <a href={"https://ieeer8.org/category/technical-activities/professional-and-educational-activities/"}>
            <img src={pea} alt="Logo" style={{ padding: 2, margin: 5 }} width={windowSize.innerWidth / 4} />
          </a>
        </div>
      </div>
    );

  return (
    <div
      style={{
        backgroundColor: PRIMARY,
        position: "relative",
        bottom: 0,
        width: "100%",
      }}
    >
      <div style={{ padding: 10 }}>
        <h3 className="center" style={{ color: "white" }}>
          Main Sponsors
        </h3>
        {mainSponsors}
      </div>
      <div style={{ backgroundColor: "white", padding: 10 }}>
        <h3 className="center" style={{ color: PRIMARY }}>
          Supported By
        </h3>
        {supportBy}
      </div>
    </div>
  );
}
